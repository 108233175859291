<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <a-locale-provider :locale="locale">
    <router-view />
    <Kefu></Kefu>
  </a-locale-provider>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import Kefu from '@/components/Kefu.vue';
import { getConfig,checkLogin } from "./utils/api";
export default {
  components: {
    Kefu
  },
  data() {
    return {
      config: [],
      locale: zhCN,
    };
  },
  created() {
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute(
        "content",
        "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover"
      );
    this.getWebConfig();
    this.checkLogin()
  },
  mounted() {
  },
  methods: {
    checkLogin() {
      checkLogin().then({}).catch({})
    },
    getWebConfig() {
      getConfig()
        .then((res) => {
          this.config = res.data;
          this.$store.commit("setConfig", res.data);
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
html {
  height: 100%;
}
body {
  background: #efefef;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  height: 100%;
  position: relative;
}

.copyright {
  padding-bottom: 4rem;
  background: #fff;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.copyright a {
  color: #333;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #333;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.van-dialog__header {
  box-sizing: border-box;
  padding: 1rem;
}
.loading-status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.loading-status .van-loading .van-loading__text {
  color: rgb(255, 255, 255);
}
.banklist-select{
  z-index: 999999 !important;
}
</style>
